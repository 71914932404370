import { handleLogWorkErrors } from '@core/app/slices/logwork';
import { Locale } from '@core/app/types/globalApiType';
import { CalendarVariablesRequest, CalendarVariablesResponse, JobsRequest, JobsResponse, JobsResponseData, LogWorkErrors, LoggedHoursResponse, LoggedWorkByIdData, LoggedWorkByIdResponse, SetAdditionRequest, SetHoursRequest, SetHoursResponse, SetManualHoursRequest, SetPieceworkRequest, SkipShiftRequest, UpdateLoggedWorkAdditionRequest, UpdateLoggedWorkHourRequest, UpdateLoggedWorkPieceworkRequest } from '@core/app/types/logworkApiType';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const logworkApi = createApi({
    reducerPath: 'logworkApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.ENV === 'local' ? `${process.env.API_URL}/api` : `${window.location.origin}/api`,
        prepareHeaders: (headers) => {
            const token = document.getElementsByName('csrf-token')[0]?.getAttribute('content')
            if (token) headers.set('X-CSRF-TOKEN', token)

            return headers
        },
        credentials: process.env.ENV === 'local' ? 'include' : 'same-origin',
    }),
    tagTypes: ['LoggedWorkById'],
    endpoints: (builder) => ({
        getLocale: builder.query<Locale, void>({
            query: () => ({
                url: '/locale',
                method: 'GET',
            }),
        }),
        getJobs: builder.mutation<JobsResponseData, JobsRequest>({
            query: (body) => ({
                url: '/employee/work/jobs',
                method: 'POST',
                body
            }),
            transformResponse: (response: JobsResponse) => {
                return response.data
            },
        }),
        setLoggedHours: builder.mutation<any, SetHoursRequest | SetManualHoursRequest | SetPieceworkRequest | SetAdditionRequest>({
            query: (body) => {
                let url = '/employee/work/log'

                if ('addition' in body.data) {
                    url = '/employee/work/log/addition'
                }
        
                return {
                    url: url,
                    method: 'POST',
                    body: body.data,
                }
            },
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    dispatch(logworkApi.endpoints.getLoggedHours.initiate(arg.date))
                    dispatch(logworkApi.endpoints.getCalendarVariables.initiate({ scopes: ['shifts'], fromDate: arg.date.start, toDate: arg.date.end }))
                } catch (error: unknown) {
                    const errorResponse = error as { error: LogWorkErrors }
                    console.error('Error setting logged hours: ', errorResponse.error?.data?.data?.errors)
                    if (errorResponse.error?.data?.data?.errors?.hours) {
                        dispatch(handleLogWorkErrors(errorResponse.error?.data?.data?.errors?.hours))
                    } else if (errorResponse.error?.data?.data?.errors?.manualHours) {
                        dispatch(handleLogWorkErrors(errorResponse.error?.data?.data?.errors?.manualHours))
                    } else if (errorResponse.error?.data?.data?.errors?.piecework) {
                        dispatch(handleLogWorkErrors(errorResponse.error?.data?.data?.errors?.piecework))
                    } else if (errorResponse.error?.data?.data?.errors?.addition) {
                        dispatch(handleLogWorkErrors(errorResponse.error?.data?.data?.errors?.addition))
                    }
                }
            },
            transformResponse: (response: SetHoursResponse) => response.data.result,
        }),
        getLoggedHours: builder.mutation<any, JobsRequest>({
            query: (body) => ({
                url: '/employee/work/logged-work',
                method: 'POST',
                body
            }),
            transformResponse: (response: LoggedHoursResponse) => response.data,
        }),
        deleteLoggedHours: builder.mutation<any, { ids: number[], date: {
            start: string
            end: string
        } }>({
            query: (body) => ({
                url: '/employee/work/delete',
                method: 'DELETE',
                body
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    dispatch(logworkApi.endpoints.getLoggedHours.initiate(arg.date))
                } catch (error) {
                    console.error('Error setting logged hours: ', error)
                }
            },
        }),
        getCalendarVariables: builder.mutation<any, CalendarVariablesRequest>({
            query: (body) => ({
                url: '/employee/calendar',
                method: 'POST',
                body
            }),
            transformResponse: (response: CalendarVariablesResponse) => response.data
        }),
        skipShift: builder.mutation<void, SkipShiftRequest>({
            query: (body) => ({
                url: '/employee/calendar/skip/shift',
                method: 'POST',
                body
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    dispatch(logworkApi.endpoints.getCalendarVariables.initiate({ scopes: ['shifts'], fromDate: arg.fromDate, toDate: arg.toDate }))
                } catch (error) {
                    console.error('Error deleting calendar shift: ', error)
                }
            },
        }),
        getLoggedWorkById: builder.query<LoggedWorkByIdData, number>({
            query: (id) => ({
                url: `/employee/work/${id}`,
                method: 'GET',
            }),
            transformResponse: (response: LoggedWorkByIdResponse) => response.data,
            providesTags: ['LoggedWorkById'],
        }),
        deleteFileFromWork: builder.mutation<void, { fileId: number, workHourId: number, dates: { start: string, end: string } }>({
            query: (body) => ({
                url: `/employee/work/${body.workHourId}/delete/${body.fileId}`,
                method: 'DELETE',
                body
            }),
            invalidatesTags: ['LoggedWorkById'],
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    dispatch(logworkApi.endpoints.getLoggedHours.initiate(arg.dates))
                } catch (error: unknown) {
                    const errorResponse = error as { error: LogWorkErrors }
                    console.error('Error setting logged hours: ', errorResponse.error?.data?.data?.errors)
                    if (errorResponse.error?.data?.data?.errors?.hours) {
                        dispatch(handleLogWorkErrors(errorResponse.error?.data?.data?.errors?.hours))
                    } else if (errorResponse.error?.data?.data?.errors?.manualHours) {
                        dispatch(handleLogWorkErrors(errorResponse.error?.data?.data?.errors?.manualHours))
                    } else if (errorResponse.error?.data?.data?.errors?.piecework) {
                        dispatch(handleLogWorkErrors(errorResponse.error?.data?.data?.errors?.piecework))
                    } else if (errorResponse.error?.data?.data?.errors?.addition) {
                        dispatch(handleLogWorkErrors(errorResponse.error?.data?.data?.errors?.addition))
                    }
                }
            },
        }),
        updateLoggedWorkHour: builder.mutation<void, UpdateLoggedWorkHourRequest>({
            query: (body) => ({
                url: `/employee/work/edit/${body.workHourId}/hour`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['LoggedWorkById'],
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    dispatch(logworkApi.endpoints.getCalendarVariables.initiate({ scopes: ['shifts'], fromDate: arg.dates.start, toDate: arg.dates.end }))
                    dispatch(logworkApi.endpoints.getLoggedHours.initiate(arg.dates))
                } catch (error: unknown) {
                    const errorResponse = error as { error: LogWorkErrors }
                    console.error('Error setting logged hours: ', errorResponse.error?.data?.data?.errors)
                    if (errorResponse.error?.data?.data?.errors?.hours) {
                        dispatch(handleLogWorkErrors(errorResponse.error?.data?.data?.errors?.hours))
                    } else if (errorResponse.error?.data?.data?.errors?.manualHours) {
                        dispatch(handleLogWorkErrors(errorResponse.error?.data?.data?.errors?.manualHours))
                    } else if (errorResponse.error?.data?.data?.errors?.piecework) {
                        dispatch(handleLogWorkErrors(errorResponse.error?.data?.data?.errors?.piecework))
                    } else if (errorResponse.error?.data?.data?.errors?.addition) {
                        dispatch(handleLogWorkErrors(errorResponse.error?.data?.data?.errors?.addition))
                    }
                }
            },
        }),
        updateLoggedWorkPiecework: builder.mutation<void, UpdateLoggedWorkPieceworkRequest>({
            query: (body) => ({
                url: `/employee/work/edit/${body.workHourId}/piecework`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['LoggedWorkById'],
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    dispatch(logworkApi.endpoints.getCalendarVariables.initiate({ scopes: ['shifts'], fromDate: arg.dates.start, toDate: arg.dates.end }))
                    dispatch(logworkApi.endpoints.getLoggedHours.initiate(arg.dates))
                } catch (error: unknown) {
                    const errorResponse = error as { error: LogWorkErrors }
                    console.error('Error setting logged hours: ', errorResponse.error?.data?.data?.errors)
                    if (errorResponse.error?.data?.data?.errors?.hours) {
                        dispatch(handleLogWorkErrors(errorResponse.error?.data?.data?.errors?.hours))
                    } else if (errorResponse.error?.data?.data?.errors?.manualHours) {
                        dispatch(handleLogWorkErrors(errorResponse.error?.data?.data?.errors?.manualHours))
                    } else if (errorResponse.error?.data?.data?.errors?.piecework) {
                        dispatch(handleLogWorkErrors(errorResponse.error?.data?.data?.errors?.piecework))
                    } else if (errorResponse.error?.data?.data?.errors?.addition) {
                        dispatch(handleLogWorkErrors(errorResponse.error?.data?.data?.errors?.addition))
                    }
                }
            },
        }),
        updateLoggedWorkAddition: builder.mutation<void, UpdateLoggedWorkAdditionRequest>({
            query: (body) => ({
                url: `/employee/work/edit/${body.workHourId}/addition`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['LoggedWorkById'],
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    dispatch(logworkApi.endpoints.getCalendarVariables.initiate({ scopes: ['shifts'], fromDate: arg.dates.start, toDate: arg.dates.end }))
                    dispatch(logworkApi.endpoints.getLoggedHours.initiate(arg.dates))
                } catch (error: unknown) {
                    const errorResponse = error as { error: LogWorkErrors }
                    console.error('Error setting logged hours: ', errorResponse.error?.data?.data?.errors)
                    if (errorResponse.error?.data?.data?.errors?.hours) {
                        dispatch(handleLogWorkErrors(errorResponse.error?.data?.data?.errors?.hours))
                    } else if (errorResponse.error?.data?.data?.errors?.manualHours) {
                        dispatch(handleLogWorkErrors(errorResponse.error?.data?.data?.errors?.manualHours))
                    } else if (errorResponse.error?.data?.data?.errors?.piecework) {
                        dispatch(handleLogWorkErrors(errorResponse.error?.data?.data?.errors?.piecework))
                    } else if (errorResponse.error?.data?.data?.errors?.addition) {
                        dispatch(handleLogWorkErrors(errorResponse.error?.data?.data?.errors?.addition))
                    }
                }
            },
        })
    }),
})

export const {useGetLocaleQuery, useGetJobsMutation, useSetLoggedHoursMutation, useGetLoggedHoursMutation, useDeleteLoggedHoursMutation, useGetCalendarVariablesMutation, useSkipShiftMutation, useGetLoggedWorkByIdQuery, useDeleteFileFromWorkMutation, useUpdateLoggedWorkHourMutation, useUpdateLoggedWorkPieceworkMutation, useUpdateLoggedWorkAdditionMutation } = logworkApi
