import { ChakraProvider } from '@chakra-ui/react'
import store from '@core/app/store/store'
import { IsApplyDialogProvider } from '@core/utils/contexts/ApplyDialogContext/ApplDialogContext'
import { ModalProvider } from '@core/utils/contexts/ModalContext/ModalContextProvider'
import { ThemeProvider } from '@core/utils/contexts/ThemeContext/ThemeContext'
import ErrorBoundary from '@core/utils/helpers/errorBoundary'

import '@core/utils/i18n'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import '../../index.css'
import theme from '../../theme.js'
import { CompanyPage } from './CompanyPage'
import * as Sentry from '@sentry/react'

Sentry.init({
    dsn: 'https://a4abf369a456074fb70f84081165999e@o4506197688647680.ingest.us.sentry.io/4506707730825216',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/.*/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.ENV, 
    beforeSend: (event) => {
        if (process.env.ENV === 'local') {
            return null; 
        }
        
        // Ignore timeout errors
        if (event.exception && event.exception.values) {
            const exception = event.exception.values[0];
            if (exception.value && exception.value.includes('Timeout')) {
                return null;
            }
        }
        
        // Ignore non-error promise rejections with value: Timeout
        if (event.message && event.message.includes('Non-Error promise rejection captured with value: Timeout')) {
            return null;
        }

        return event; 
    }
})

const app = document.getElementById('root')
const root = createRoot(app!)

const router = createBrowserRouter([
    {
        path: process.env.ENV === 'local' ? '' : '/',
        element: (
            <ErrorBoundary fallback={<div>Something went wrong...</div>}>
                <IsApplyDialogProvider>
                    <ThemeProvider>
                        <CompanyPage />
                    </ThemeProvider>
                </IsApplyDialogProvider>
            </ErrorBoundary>
        ),
    },
])

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ChakraProvider theme={theme}>
                <ModalProvider>
                    <RouterProvider router={router} />
                </ModalProvider>
            </ChakraProvider>
        </Provider>
    </React.StrictMode>
)
