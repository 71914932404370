import { ApplyForJob, ApplyJobSettings, ApplySuccess, JobApplicationResponse, PostInfo, Posts, ScreeningQuestions } from '@core/app/types/postsApiType';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import queryString from 'query-string';
import {ResultType} from "@remix-run/router/utils";

export const postsApi = createApi({
    reducerPath: 'postsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.ENV === 'local' ? `${process.env.API_URL}/api` : `${window.location.origin}/api`,
        prepareHeaders: (headers) => {
            const token = document.getElementsByName('csrf-token')[0]?.getAttribute('content')
            if (token) headers.set('X-CSRF-TOKEN', token)

            return headers
        },
        credentials: process.env.ENV === 'local' ? 'include' : 'same-origin'
    }),
    tagTypes: ['JobApplications'],
    endpoints: (builder) => ({
        getPosts: builder.query<Posts, {country?: number[], industry?: number[], city?: number[], jobType?: number[], office?: number[], region?: number[], query?: string, sort?: string}>({
            query: ({country, industry,city, jobType, office, region, query, sort}) => ({
                url: `/jobs?${queryString.stringify({query: query, country: country, city: city, region: region, branch: industry, extent: jobType, department: office, sort: sort}, {arrayFormat: 'bracket'})}`,
                method: 'GET',
            }),
            transformResponse(baseQueryReturnValue: any): Posts {
                // const oldArea = baseQueryReturnValue.data?.filters?.area || {};
                // const areas = Object.entries(oldArea).filter(([key, value]: [string, any]) => key === '38');
                // if (areas.length > 0) {
                //     const [areaKey, areaValue] = areas[0] || {};
                //     return  {
                //         ...baseQueryReturnValue,
                //         data: {
                //             ...(baseQueryReturnValue.data || {}),
                //             filters: {
                //                 ...(baseQueryReturnValue.data?.filters || {}),
                //                 area: {
                //                     [areaKey]: areaValue
                //                 }
                //             }
                //         }
                //     }
                // }
                return baseQueryReturnValue;
            }
        }),
        getPostsByPage: builder.query<Posts, {department?: number[], country?: number[], region?: number[], branch?: number[], extent?: number[], query?: string, page?: number, limit?: number}>({
            query: (params) => ({
                url: `/jobs?${queryString.stringify(params, { arrayFormat: 'bracket' })}`,
                method: 'GET',
            }),
        }),
        getPostInfo: builder.query<PostInfo, {id: string | undefined}>({
            query: ({id}) => ({
                url: `/job/${id}`,
                method: 'GET'
            })
        }),
        getPostInfoPreview: builder.query<PostInfo, {getParams: string | undefined}>({
            query: ({getParams}) => ({
                url: `/job/preview${getParams}`,
                method: 'GET'
            })
        }),
        getPostSettings: builder.query<ApplyJobSettings, {id: string, code: string, path?: string}>({
            query: ({id, code, path}) => ({
                url: `/apply/${id}/settings${code && code.length > 0 ? code : ''}${path && path.length > 0 ? `?path=${path}` : ''}`,
                method: 'GET',
            })
        }),
        applyForJob: builder.mutation<ApplySuccess, ApplyForJob>({
            query: (body) => ({
                url: `/job/${body.id}/apply`,
                method: 'POST',
                body
            })
        }),
        setJobApplyCandidate: builder.mutation<void, {id: string, data: {anonymousApplication?: boolean, applicationText?: string, anonymousReason?: string, screeningQuestions?: ScreeningQuestions, 'g-recaptcha-response': string, attachments: { base64: string, ext: string, name: string }[]}}>({
            query: (body) => ({
                url: `/profile/job/${body.id}/apply`,
                method: 'POST',
                body
            })
        }),
        getJobApplications: builder.query<JobApplicationResponse, any>({
            query: () => ({
                url: '/profile/application',
                method: 'GET'
            }),
            providesTags: ['JobApplications']
        }),
        deleteJobApplication: builder.mutation<void, { id: number }>({
            query: ({id}) => ({
                url: `/profile/application/${id}/delete`,
                method: 'DELETE'
            }),
            invalidatesTags: ['JobApplications']
        })
    }),
})

export const { useGetPostsQuery, useGetPostsByPageQuery, useGetPostInfoQuery, useGetPostInfoPreviewQuery, useGetPostSettingsQuery, useApplyForJobMutation, useSetJobApplyCandidateMutation, useGetJobApplicationsQuery, useDeleteJobApplicationMutation } = postsApi
